import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { isValid } from 'date-fns'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { nameOfSquare } from '../../chess/basics'
import {
    addMoveToGTPosition,
    getLastPosition,
    getMoveById,
    getNextPosition,
    getPositionById,
    getPreviousPosition,
    getStartingPosition,
} from '../../chess/gameTree'
import { createPuzzlePGNString, gameFromPGN } from '../../chess/pgn'
import { Move } from '../../chess/types'
import AppLayout from '../../components/AppLayout/AppLayout'
import { NotificationDialog } from '../../components/overlays/NotificationDialog/NotificationDialog'
import { Square } from '../../react-chessboard/src/chessboard/types'
import { GroupNames, LoginState } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { Layout, useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import PuzzlesChessBoard from '../chessboard/PuzzlesChessboard'
import { NotationControls } from '../notation/components/NotationControls'
import DailyPuzzleButtons from './components/DailyPuzzleButtons'
import DailyPuzzleDate from './components/DailyPuzzleDate'
import PuzzleControls from './components/PuzzleControls'
import { Description } from './components/v2/Description'
import { NotationHeader } from './components/v2/NotationHeader'
import { Notations, NotationsType } from './components/v2/Notations'
import PuzzlesLimitDialog from './components/v2/PuzzleLimitDialog'
import { PuzzleStatus, getDailyPuzzle, resolveDailyPuzzle } from './functions/puzzlesApi'
import getParsedDate from './helpers/getParsedDate'
import navigateToDate from './helpers/navigateToDate'
import useResultSounds from './hooks/useResultSounds'
import { BOT_MOVING_DELAY } from './PuzzlesTrainingViewV2'

interface IPuzzle {
    id: string
    pgnString: string
    fen: string
    rating: number
    description: string
    isFirstMove: boolean
    date: Date
}

const DailyPuzzleView: React.FC = () => {
    const { date } = useParams<{ date?: string }>()
    const { layout } = useResponsiveSizings()
    const navigate = useNavigate()
    const [isPuzzleStarted, setPuzzleStarted] = useState<boolean>(false)
    const [isLastMoveCorrect, setIsLastMoveCorrect] = useState<boolean>(false)
    const [welcomeDescOpen, setWelcomeDescOpen] = useState<boolean>(false)
    const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false)
    const [showSolution, setShowSolution] = useState<boolean>(false)
    const [usedShowSolution, setUsedShowSolution] = useState<boolean>(false)
    const [currentPuzzle, setCurrentPuzzle] = useState<IPuzzle | null>(null)
    const [currentPuzzleState, setCurrentPuzzleState] = useState<boolean | null>(null)
    const [waitingForOpponentMove, setWaitingForOpponentMove] = useState<boolean>(false)
    const [isBotMoving, setIsBotMoving] = useState<boolean>(false)
    const [hint, setHint] = useState<Square>()
    const [hintsCounter, setHintsCounter] = useState<number>(0)
    const [attemptsCounter, setAttemptsCounter] = useState<number>(1)
    const [notations, setNotations] = useState<Array<NotationsType>>([])
    const [joinUsOpen, setJoinUsOpen] = useState<boolean>(false)

    const puzzleGameTree = useStoreState((state) => state.dailyPuzzleView.puzzleGameTree)
    const gameTree = useStoreState((state) => state.dailyPuzzleView.gameTree)
    const progressCurrentPositionId = useStoreState((state) => state.dailyPuzzleView.progressCurrentPositionId)
    const currentPositionId = useStoreState((state) => state.dailyPuzzleView.currentPositionId)
    const flipped = useStoreState((state) => state.dailyPuzzleView.flipped)
    const myColor = useStoreState((state) => state.dailyPuzzleView.myColor)
    const token = useStoreState((state) => state.token)
    const userDataState = useStoreState((state) => state.userData.state)
    const userGroups = useStoreState((state) => state.userData.userData?.groups)
    const userRating = useStoreState((state) => state.dailyPuzzleView.userRating)
    const settings = useStoreState((state) => state.gameView.settings)

    const setProgressCurrentPositionId = useStoreActions((state) => state.dailyPuzzleView.setProgressCurrentPositionId)
    const setCurrentPositionId = useStoreActions((state) => state.dailyPuzzleView.setCurrentPositionId)
    const initialiseGame = useStoreActions((state) => state.dailyPuzzleView.initialiseGame)
    const setFlipBoard = useStoreActions((state) => state.dailyPuzzleView.setFlipBoard)
    const undoLastMove = useStoreActions((state) => state.dailyPuzzleView.undoLastMove)
    const resetGame = useStoreActions((state) => state.dailyPuzzleView.resetGame)
    const setUserRating = useStoreActions((state) => state.dailyPuzzleView.setUserRating)
    const setValidations = useStoreActions((state) => state.dailyPuzzleView.setValidations)

    const playResultSound = useResultSounds(settings)
    const retryTimeout = useRef<NodeJS.Timeout>()
    const autoMoveTimeout = useRef<NodeJS.Timeout>()
    const opponentMoveTimeout = useRef<NodeJS.Timeout>()

    const gtPos = useMemo(() => getPositionById(gameTree, currentPositionId), [gameTree, currentPositionId])
    let loadFirstPuzzle = false

    // ---------- load data ---------- //

    // load puzzle data
    const getNextPuzzle = async () => {
        const parsedDate = getParsedDate(date)
        const puzzleData = await getDailyPuzzle(parsedDate)
        if (puzzleData === null) {
            loadFirstPuzzle = false
            navigateToDate(navigate)
            return
        }
        if (puzzleData?.puzzle && puzzleData.date) {
            const puzzle = puzzleData.puzzle
            const pgnString = createPuzzlePGNString(puzzle.startingFen, puzzle.moves)

            setUserRating(puzzleData.userPuzzleRating || 0)
            setCurrentPuzzle({
                id: puzzle.id,
                pgnString: pgnString,
                fen: puzzle.startingFen,
                rating: puzzle.rating,
                description: puzzle.description,
                isFirstMove: puzzle.isFirstMove,
                date: new Date(puzzleData.date),
            })
        }

        if (puzzleData?.nextPuzzleAvailable !== undefined && puzzleData?.previousPuzzleAvailable !== undefined) {
            setValidations({
                prevDate: puzzleData.previousPuzzleAvailable,
                nextDate: puzzleData.nextPuzzleAvailable,
            })
        }

        const isGuest = userGroups?.some((group: any) => group.name === GroupNames.GUEST)
        if (isGuest) {
            setJoinUsOpen(true)
        }
    }

    // clear all intervals
    const clearAllTimers = () => {
        clearTimeout(opponentMoveTimeout.current)
        clearTimeout(autoMoveTimeout.current)
        clearTimeout(retryTimeout.current)
    }

    // reset all states to default
    const resetAllStates = () => {
        clearAllTimers()
        setCurrentPuzzle(null)
        resetGame()
        setHint(undefined)
        setHintsCounter(0)
        setAttemptsCounter(1)
        setUsedShowSolution(false)
        setPuzzleStarted(false)
    }

    // load puzzle data
    useEffect(() => {
        if (userDataState === LoginState.NOT_LOGGED_IN) {
            setJoinUsOpen(true)
            loadFirstPuzzle = false
            resetAllStates()
        } else {
            setJoinUsOpen(false)
            if (token && loadFirstPuzzle === false) {
                loadFirstPuzzle = true
                resetAllStates()
                getNextPuzzle()
            }
        }
    }, [userDataState])

    useEffect(() => {
        return () => {
            resetAllStates()
        }
    }, [])

    useEffect(() => {
        if (date && isValid(new Date(date)) === false) {
            navigateToDate(navigate)
            return
        }

        const parsedDate = getParsedDate(date)
        if (currentPuzzle && parsedDate.getTime() !== currentPuzzle.date.getTime()) {
            clearAllTimers()

            sendPuzzle()

            setHint(undefined)
            setHintsCounter(0)
            setAttemptsCounter(1)
            setCurrentPuzzleState(null)
            setUsedShowSolution(false)
            setPuzzleStarted(false)

            getNextPuzzle()
        } else if (!currentPuzzle && loadFirstPuzzle === false) {
            loadFirstPuzzle = true
            resetAllStates()
            getNextPuzzle()
        }
    }, [date])

    // ---------- end load data ---------- //

    // ---------- puzzle init and controls ---------- //

    // init game board
    useEffect(() => {
        if (currentPuzzle && !joinUsOpen) {
            const puzzleGameTree = gameFromPGN(currentPuzzle.pgnString)
            initialiseGame({
                gameTree: puzzleGameTree,
                isFirstMove: currentPuzzle.isFirstMove,
            })
            setCurrentPuzzleState(null)

            // opponent first move
            if (!currentPuzzle.isFirstMove) {
                opponentMoveTimeout.current = setTimeout(() => {
                    setIsBotMoving(true)
                    setWaitingForOpponentMove(true)
                    setPuzzleStarted(true)
                }, 1750)
            } else {
                setPuzzleStarted(true)
            }
        }
    }, [currentPuzzle, joinUsOpen])

    // resolve puzzle or retry after error
    useEffect(() => {
        if (currentPuzzleState === true) {
            // resolve current puzzle
            if (showSolution) {
                setShowSolution(false)
                setUsedShowSolution(true)
            }
            playResultSound(true)
            sendPuzzle()
            clearAllTimers()
        } else if (currentPuzzleState === false) {
            // retry current puzzle
            setAttemptsCounter(attemptsCounter + 1)
            playResultSound(false)
            retryTimeout.current = setTimeout(() => {
                undoLastMove(gameTree)
                setCurrentPuzzleState(null)
            }, 2500)
        }
    }, [currentPuzzleState])

    // resolve puzzle
    const sendPuzzle = useCallback(async () => {
        if (currentPuzzle) {
            let status = PuzzleStatus.FAILED
            if (usedShowSolution) {
                status = PuzzleStatus.SHOWED
            } else if (currentPuzzleState === true) {
                status = PuzzleStatus.PASSED
            } else if (currentPuzzleState === null && hintsCounter === 0 && attemptsCounter === 1) {
                status = PuzzleStatus.SKIPPED
            }

            const data = await resolveDailyPuzzle({
                puzzleId: currentPuzzle.id,
                status: status,
                usedHints: hintsCounter,
                attempts: attemptsCounter,
            })

            if (data.result) {
                analyticsManager.dispatchEvent('completedPuzzle', {
                    origin: 'dailyPuzzle',
                    datePlayed: new Date().toISOString(),
                    puzzleId: currentPuzzle.id,
                    puzzleRating: currentPuzzle.rating,
                    puzzleSource: 0,
                    userPuzzleRating: userRating,
                    result: status,
                    attempts: attemptsCounter,
                    usedHints: hintsCounter,
                })
                setUserRating(data.userPuzzleRating || 0)
            }
        }
    }, [currentPuzzle, usedShowSolution, currentPuzzleState, hintsCounter, attemptsCounter])

    // ---------- end puzzle init and controls ---------- //

    // ---------- moves ---------- //

    // opponent or show solution auto move
    const autoMove = () => {
        const currentProgressPosition = getPositionById(puzzleGameTree, progressCurrentPositionId)

        const nextProgressMove = getMoveById(puzzleGameTree, currentProgressPosition?.nextMoveIds[0])
        if (nextProgressMove) {
            setProgressCurrentPositionId(nextProgressMove.nextPositionId)
            const currentPosition = getPositionById(gameTree, currentPositionId)
            const nextPosition = addMoveToGTPosition(gameTree, currentPosition, nextProgressMove.move)
            setCurrentPositionId(nextPosition.id)

            if (currentProgressPosition.position.turn === myColor) {
                const nextProgressPosition = getPositionById(puzzleGameTree, nextProgressMove.nextPositionId)
                if (nextProgressPosition.nextMoveIds.length === 0) {
                    setHint(undefined)
                    setCurrentPuzzleState(true)
                } else {
                    opponentMoveTimeout.current = setTimeout(() => {
                        setIsBotMoving(true)
                        setWaitingForOpponentMove(true)
                    }, BOT_MOVING_DELAY)
                }
            }
        }
    }

    // opponent move or show solution move
    useEffect(() => {
        setHint(undefined)
        if (waitingForOpponentMove) {
            autoMove()
            setWaitingForOpponentMove(false)
            setTimeout(() => {
                setIsBotMoving(false)
            }, BOT_MOVING_DELAY)
        } else if (!waitingForOpponentMove && showSolution) {
            autoMoveTimeout.current = setTimeout(() => {
                autoMove()
            }, BOT_MOVING_DELAY)
        }
    }, [waitingForOpponentMove, showSolution])

    // manually move
    const onMove = useCallback(
        (move: Move) => {
            // if it not my turn, no interaction
            if (gtPos.position.turn !== myColor) return false

            const currentProgressPosition = getPositionById(puzzleGameTree, progressCurrentPositionId)
            const expectedProgressMove = getMoveById(puzzleGameTree, currentProgressPosition?.nextMoveIds[0])

            const currentPosition = getPositionById(gameTree, currentPositionId)

            if (!expectedProgressMove) return false

            const isMoveCorrect =
                move.from === expectedProgressMove.move.from &&
                move.to === expectedProgressMove.move.to &&
                move.promotion === expectedProgressMove.move.promotion

            if (isMoveCorrect) {
                setProgressCurrentPositionId(expectedProgressMove.nextPositionId)
                const nextPosition = addMoveToGTPosition(gameTree, currentPosition, expectedProgressMove.move)
                setCurrentPositionId(nextPosition.id)

                const nextProgressPosition = getPositionById(puzzleGameTree, expectedProgressMove.nextPositionId)
                if (nextProgressPosition.nextMoveIds.length === 0) {
                    setHint(undefined)
                    setCurrentPuzzleState(true)
                } else {
                    setIsLastMoveCorrect(true)
                    opponentMoveTimeout.current = setTimeout(() => {
                        setIsBotMoving(true)
                        setIsLastMoveCorrect(false)
                        setWaitingForOpponentMove(true)
                    }, BOT_MOVING_DELAY)
                }

                return true
            }

            const nextPosition = addMoveToGTPosition(gameTree, currentPosition, move)
            setCurrentPositionId(nextPosition.id)

            setCurrentPuzzleState(false)
            return false
        },
        [gameTree, currentPositionId, setCurrentPositionId],
    )

    // ---------- end moves ---------- //

    // ---------- circle buttons ---------- //

    // show solution handler
    const onShowSolutionClick = () => {
        setUsedShowSolution(true)
        setShowSolution(true)
    }

    // flip board handler
    const onFlipClick = () => {
        setFlipBoard(!flipped)
    }

    // hint handler
    const onHintClick = () => {
        if (!hint) {
            setHintsCounter(hintsCounter + 1)
            const currentProgressPosition = getPositionById(puzzleGameTree, progressCurrentPositionId)
            const expectedProgressMove = getMoveById(puzzleGameTree, currentProgressPosition?.nextMoveIds[0])
            if (expectedProgressMove && currentProgressPosition.position.turn === myColor) {
                const nextMove = nameOfSquare(expectedProgressMove?.move.from)
                setHint(nextMove as Square)
            }
        }
    }

    const onShowDescriptionClick = () => {
        setDescriptionOpen(true)
    }

    // ---------- end circle buttons ---------- //

    // ---------- notation controls ---------- //

    // next and prev moves available
    const hasNextMoves = gtPos.nextMoveIds.length > 0
    const hasPrevMoves = gtPos.previousMoveId !== undefined

    // start position handler
    const onFirst = () => {
        setCurrentPositionId(getStartingPosition(gameTree).id)
    }

    // prev position handler
    const onPrev = () => {
        const previousPosition = getPreviousPosition(gameTree, currentPositionId)
        if (!previousPosition) return
        setCurrentPositionId(previousPosition.id)
    }

    // next position handler
    const onNext = () => {
        const nextPosition = getNextPosition(gameTree, currentPositionId)
        if (!nextPosition) return
        setCurrentPositionId(nextPosition.id)
    }

    // last position handler
    const onLast = () => {
        setCurrentPositionId(getLastPosition(gameTree).id)
    }

    // ---------- end notation controls ---------- //

    return (
        <>
            <AppLayout
                aboveBoard={
                    layout === Layout.MOBILE ? (
                        <DailyPuzzleDate disabled={isBotMoving || !isPuzzleStarted} />
                    ) : undefined
                }
                board={
                    <PuzzlesChessBoard
                        mode="puzzle"
                        gameTree={gameTree}
                        currentPositionId={currentPositionId}
                        flipped={flipped}
                        onMove={onMove}
                        myColor={myColor}
                        hint={hint}
                        disableBoard={gtPos.position.turn !== myColor || showSolution}
                        moveStatus={currentPuzzleState === null ? undefined : currentPuzzleState}
                    />
                }
            >
                {layout === Layout.DESKTOP && (
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            flexWrap: 'nowrap',
                            minHeight: '350px',
                            border: '.125rem solid',
                            borderColor: 'text.primary',
                            backgroundColor: 'background.paper',
                            overflow: 'auto',
                        }}
                    >
                        <Stack alignItems="center" py={2} gap={1}>
                            <Typography textAlign="center" color="secondary" variant="h2">
                                Daily puzzle
                            </Typography>
                            <DailyPuzzleDate disabled={isBotMoving || !isPuzzleStarted} />
                        </Stack>
                        <Divider sx={{ width: '100%', marginBottom: '16px' }} />
                        <NotationHeader
                            myColor={myColor}
                            usedShowSolution={usedShowSolution}
                            hint={Boolean(hint)}
                            usedAttempts={attemptsCounter > 1}
                            usedHint={Boolean(hintsCounter)}
                            isBotMoving={isBotMoving}
                            puzzleState={currentPuzzleState}
                            isPuzzleStarted={isPuzzleStarted}
                            isLastMoveCorrect={isLastMoveCorrect}
                        />
                        <Notations
                            myColor={myColor}
                            notations={notations}
                            setNotations={setNotations}
                            usedShowSolution={usedShowSolution}
                            currentHint={hint}
                            gameTree={gameTree}
                            puzzleState={currentPuzzleState}
                            currentPositionId={currentPositionId}
                            setCurrentPositionId={setCurrentPositionId}
                            disabled={currentPuzzleState === false}
                        />
                        <Description
                            myColor={myColor}
                            description={currentPuzzle?.description}
                            puzzleGameTree={puzzleGameTree}
                        />
                    </Grid>
                )}
                {layout === Layout.MOBILE && (
                    <>
                        <NotationHeader
                            myColor={myColor}
                            usedShowSolution={usedShowSolution}
                            hint={Boolean(hint)}
                            usedAttempts={attemptsCounter > 1}
                            usedHint={Boolean(hintsCounter)}
                            isBotMoving={isBotMoving}
                            puzzleState={currentPuzzleState}
                            isPuzzleStarted={isPuzzleStarted}
                            isLastMoveCorrect={isLastMoveCorrect}
                        />
                        <PuzzleControls
                            welcomeDescOpen={welcomeDescOpen}
                            showDescriptionButton={true}
                            disableByState={currentPuzzleState !== null}
                            isLastPuzzle={false}
                            showSolutionDisabled={showSolution}
                            isInitialized={Boolean(notations.length)}
                            onShowSolution={onShowSolutionClick}
                            onFlip={onFlipClick}
                            onHint={onHintClick}
                            onShowDescription={onShowDescriptionClick}
                        />
                        <DailyPuzzleButtons />
                        <Box sx={{ border: '0.125rem solid white', width: '100%', padding: '0.5rem' }}>
                            <Notations
                                myColor={myColor}
                                notations={notations}
                                setNotations={setNotations}
                                usedShowSolution={usedShowSolution}
                                currentHint={hint}
                                gameTree={gameTree}
                                puzzleState={currentPuzzleState}
                                currentPositionId={currentPositionId}
                                setCurrentPositionId={setCurrentPositionId}
                                disabled={currentPuzzleState === false}
                            />
                        </Box>

                        {/* DIALOGS */}

                        <NotificationDialog
                            handleClose={() => {
                                setWelcomeDescOpen(false)
                            }}
                            open={welcomeDescOpen}
                            header="description"
                            description="Each puzzle comes with its own unique brief, offering a helpful hint to guide you. If you prefer a greater challenge, you can easily toggle them off."
                        >
                            <Typography align="center" mt="2rem" variant="body1" color="secondary">
                                Use the brief button to open the puzzle description
                            </Typography>
                        </NotificationDialog>
                        <Description
                            dialog={{
                                open: descriptionOpen,
                                handleClose: () => {
                                    setDescriptionOpen(false)
                                },
                            }}
                            myColor={myColor}
                            puzzleGameTree={puzzleGameTree}
                            description={currentPuzzle?.description}
                        />
                    </>
                )}
                <NotationControls
                    vertical={layout === Layout.MOBILE}
                    commonDisabled={notations.length <= 1 || (usedShowSolution && currentPuzzleState === null)}
                    hasNextMoves={hasNextMoves}
                    hasPrevMoves={hasPrevMoves}
                    onFirstMove={onFirst}
                    onPrevMove={onPrev}
                    onNextMove={onNext}
                    onLastMove={onLast}
                />
                {layout === Layout.DESKTOP && (
                    <>
                        <PuzzleControls
                            showDescriptionButton={false}
                            disableByState={currentPuzzleState !== null}
                            isLastPuzzle={false}
                            showSolutionDisabled={showSolution}
                            isInitialized={Boolean(notations.length)}
                            onShowSolution={onShowSolutionClick}
                            onFlip={onFlipClick}
                            onHint={onHintClick}
                            onShowDescription={onShowDescriptionClick}
                        />
                        <DailyPuzzleButtons />
                    </>
                )}
                {joinUsOpen && (
                    <PuzzlesLimitDialog
                        variant="puzzleDailyMember"
                        open={joinUsOpen}
                        onClose={() => setJoinUsOpen(false)}
                    />
                )}
            </AppLayout>
        </>
    )
}

DailyPuzzleView.displayName = 'DailyPuzzleView'

export default DailyPuzzleView
