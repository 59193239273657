import { AxiosError } from 'axios'
import amsAPI, { getApiUrl } from '../../API/amsConfig'
import { SocialType } from '../../SocialLogin/SocialLogin'
import { ServerErrors } from '../../utils/serverErrors'
import { FTUE, Rules, Settings, UserData, UserGroup } from '../GlobalHeader'
import GetCookie from '../common/getCookie'
import { createQueryParams } from '../common/queryParams'
import removeCookie from '../common/removeCookie'
import { ValidationState } from './../forms/types'

// -------------------------------------------------------------KEYS-------------------------------------------------------------
const sessionStorageKey = '_icc_user'
export const tokenCookieKey = 'jwt'

// -------------------------------------------------------------HELPER FUNCTIONS-------------------------------------------------------------
function getTokenExpirationDate(token: string, apiUrl: string) {
    try {
        const payloadBase64 = token.split('.')[1]
        const payload = JSON.parse(atob(payloadBase64))
        const expirationTimestamp = payload.exp
        const expirationDate = new Date(expirationTimestamp * 1000)
        return expirationDate
    } catch (e) {
        removeCookie(tokenCookieKey, apiUrl)
        return new Date()
    }
}

export const getCookieDomain = (apiURL: string) => {
    if (apiURL.endsWith('.iccstage.net')) return '.iccstage.net'
    if (apiURL.endsWith('.chessnclub.net')) return '.chessnclub.net'
    return '.chessclub.com'
}

export const errorHandler = (error: any) => {
    if (error instanceof Error) {
        const axiosError = error as AxiosError
        return {
            error: {
                code: axiosError.response && axiosError.response.data && typeof axiosError.response.data === 'object' && 'code' in axiosError.response.data ? axiosError.response.data['code'] : 'SOMETHING_WENT_WRONG',
                message: axiosError.response && axiosError.response.data && typeof axiosError.response.data === 'object' && 'message' in axiosError.response.data ? axiosError.response.data['message'] : 'Something went wrong, please try again later.',
                details: axiosError.response && axiosError.response.data && typeof axiosError.response.data === 'object' && 'details' in axiosError.response.data ? axiosError.response.data['details'] : null
            }
        }
    } else {
        return { error: { message: error } }
    }
}

// -------------------------------------------------------------LOGIN FLOW-------------------------------------------------------------
export const login = async (
    identifier: string,
    password: string,
) => {
    try {
        const response = await amsAPI.post('/login', { identifier, password })
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(response.data))
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const renewToken = async (token: string, apiURL: string, forced?: boolean) => {
    if (!token || !navigator.onLine) return token
    const expirationDate = getTokenExpirationDate(token, apiURL)
    const expirationTimeLeft = expirationDate.getTime() - new Date().getTime()
    const refreshTokenInterval = 1000 * 60 * 60 * 168 // 1 week
    if (expirationTimeLeft < 0) {
        sessionStorage.setItem(sessionStorageKey, '')
        removeCookie(tokenCookieKey, getCookieDomain(apiURL))
        return undefined
    }

    if (expirationTimeLeft < refreshTokenInterval || forced) {
        try {
            const response = await amsAPI.put('/api/user/renew')
            return response.data.jwt
        } catch (error) {
            sessionStorage.setItem(sessionStorageKey, '')
            removeCookie(tokenCookieKey, getCookieDomain(apiURL))
            return undefined
        }
    }
    if (forced) {
        window?.location?.reload()
        return undefined
    }
}

export const ensureLogin = async () => {
    // check for cookie and session storage if no, nothing happens
    let token = GetCookie(tokenCookieKey)
    const apiURL = getApiUrl()
    await renewToken(token, apiURL)
    if (token) {
        const user = await loadUserProfileData()

        if (user && !user.error) {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify({ user }))
            return user
        } else {
            sessionStorage.setItem(sessionStorageKey, '')
            removeCookie(tokenCookieKey)
            return null
        }
    } else {
        return null
    }
}

export const logout = async () => {
    const apiURL = getApiUrl()
    sessionStorage.setItem(sessionStorageKey, '')
    removeCookie(tokenCookieKey, getCookieDomain(apiURL))
    //     // TODO: not implemented on backend
    // try {
    // sessionStorage.setItem(sessionStorageKey, '')
    // setCookie(tokenCookieKey, '', getCookieDomain(apiURL))
    //     const response = await fetch(`${apiURL}/logout`, {
    //         method: 'GET',
    //         mode: 'cors',
    //         headers: { 'Content-Type': 'application/json' },
    //         credentials: 'include',
    //     })

    //     const data = await response.json()

    //     if (!response.ok) {
    //         throw new Error(data.message || 'Something went wrong with the logout!')
    //     } else {
    //         sessionStorage.setItem(sessionStorageKey, '')
    //         removeCookie(tokenCookieKey)
    //         return data
    //     }
    // } catch (error) {
    //     return { error: error instanceof Error ? error.message : error }
    // }
}

export const loginSocial = async (
    socialToken: string,
    accessToken: string,
    socialType: SocialType,
    userName?: string,
    country?: string,
    over12?: boolean,
    email?: string,
    firstName?: string,
    lastName?: string,
) => {
    try {
        const response = await amsAPI.post('/loginSocial', {
            social_token: socialToken,
            access_token: accessToken,
            social_network_type: socialType,
            email: email,
            user_name: userName,
            first_name: firstName,
            last_name: lastName,
            over_12_years_old: over12,
            country: country,
        })

        sessionStorage.setItem(sessionStorageKey, JSON.stringify(response.data))

        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

// -------------------------------------------------------------REGISTRATION FLOW-------------------------------------------------------------
export const signup = async (
    email: string,
    password: string,
    user_name: string,
    country: string,
    over12: boolean,
) => {
    try {
        const currentURL = window.location.href
        const successRedirectURL = currentURL
        const failureRedirectURL = `${currentURL}${currentURL.indexOf('?') > -1 ? '&' : '?'}emailConfirmed=false`

        const response = await amsAPI.post('/register', {
            email,
            password,
            user_name,
            country,
            over_12_years_old: over12,
            successRedirectURL,
            failureRedirectURL,
        })

        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const guestRegistration = async () => {
    try {
        const response = await amsAPI.post('/registerGuest', {})

        sessionStorage.setItem(sessionStorageKey, JSON.stringify(response.data))
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const upgradeGuest = async (
    email: string,
    password: string,
    user_name: string,
    country: string,
    over12: boolean,
) => {
    try {
        const currentURL = window.location.href
        const successRedirectURL = currentURL
        const failureRedirectURL = `${currentURL}${currentURL.indexOf('?') > -1 ? '&' : '?'}emailConfirmed=false`

        const response = await amsAPI.post('/api/user/upgradeGuest', {
            email,
            password,
            user_name,
            country,
            over_12_years_old: over12,
            successRedirectURL,
            failureRedirectURL,
        })

        logout()

        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const upgradeGuestSocial = async (
    socialToken: string,
    accessToken: string,
    socialType: SocialType,
    logoutHandler: () => Promise<void>,
    userName?: string,
    country?: string,
    over12?: boolean,
) => {
    try {
        const response = await amsAPI.post('/api/user/upgradeGuestSocial', {
            social_token: socialToken,
            access_token: accessToken,
            social_network_type: socialType,
            user_name: userName,
            country,
            over_12_years_old: over12,
        })

        sessionStorage.setItem(sessionStorageKey, JSON.stringify(response.data))

        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const isEmailTaken = async (email: string, callback: (taken: boolean) => void) => {
    const encodedEmail = encodeURIComponent(email)
    try {
        const response = await amsAPI.get(`/isEmailTaken${createQueryParams({
            email: encodedEmail,
        })}`)

        callback(response.data.taken)
    } catch (error) {
        callback(true)
    }
}

export const validateUsername = async (
    userName: string,
    callback: (result: ValidationState) => void,
) => {
    try {
        const response = await amsAPI.get(`/usernameTaken${createQueryParams({
            userName,
        })}`)

        if (response.data.taken) {
            callback(ValidationState.TAKEN)
            return
        }

        callback(ValidationState.FREE)
    } catch (error) {
        if (error instanceof Error) {
            const axiosError = error as AxiosError
            if (axiosError.response && axiosError.response.data &&
                typeof axiosError.response.data === 'object' && 'code' in axiosError.response.data &&
                axiosError.response.data['code'] === ServerErrors.USERNAME_IS_NOT_ALLOWED) {
                callback(ValidationState.NOT_ALLOWED)
            } else {
                callback(ValidationState.ERROR)
            }
        } else {
            callback(ValidationState.ERROR)
        }
    }
}

export const forgotPassword = async (email: string) => {
    try {
        const response = await amsAPI.post('/newPasswordEmail', {
            email: email
        })
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const resetPassword = async (token: string, password: string) => {
    try {
        const response = await amsAPI.post('/resetPassword', {
            reset_token: token,
            new_password: password
        })
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const changePassword = async (currentPassword: string, newPassword: string) => {
    try {
        const response = await amsAPI.put('/api/user/password', {
            current_password: currentPassword,
            new_password: newPassword,
        })
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const resendConfirmationEmail = async (email: string) => {
    try {
        const currentURL = window.location.origin + window.location.pathname
        const successRedirectURL = encodeURIComponent(currentURL)
        const failureRedirectURL = encodeURIComponent(`${currentURL}?emailConfirmed=false`)

        const encodedEmail = encodeURIComponent(email)
        const response = await amsAPI.get(`/resendConfirmationEmail${createQueryParams({
            email: encodedEmail,
            successRedirectURL,
            failureRedirectURL,
        })}`)

        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

// -------------------------------------------------------------USER PROFILE-------------------------------------------------------------
export const getUserData = () => {
    if (typeof window === 'undefined') return null
    const userData = sessionStorage.getItem(sessionStorageKey)
    if (!!userData) {
        const parsedData = JSON.parse(userData)
        if (parsedData.error) {
            return null
        }
        return parsedData
    }
    return null
}

export const loadUserProfileData = async () => {
    try {
        const response = await amsAPI.get('/api/user/profile')
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const updateSessionSettings = (settings: Settings) => {
    const userData = sessionStorage.getItem(sessionStorageKey)
    if (!!userData) {
        const parsedData = JSON.parse(userData)
        parsedData.user.game_settings = settings
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedData))
    }
}

export const updateSessionUser = (user: UserData) => {
    const userData = sessionStorage.getItem(sessionStorageKey)
    if (!!userData) {
        const parsedData = JSON.parse(userData)
        parsedData.user = user
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(parsedData))
    }
}

export const saveUserSettings = async (settings: Settings) => {
    try {
        const response = await amsAPI.put('/api/user/settings', settings)
        updateSessionSettings(settings)
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const editProfile = async (userData: UserData) => {
    try {
        const response = await amsAPI.put('/api/user/profile', userData)
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const updateFTUE = async (ftue: Partial<Record<FTUE, boolean>>) => {
    try {
        const response = await amsAPI.patch('/api/user/ftue', ftue)
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const getUserAccess = (accessRule: Rules) => {
    let isEnabled = false
    const data = getUserData()
    const groups: Array<UserGroup> = data?.user?.groups || null
    if (groups) {
        const ruleNames = groups.flatMap((group) => group.rules.map((rule) => rule.name))
        isEnabled = ruleNames?.some((rule) => rule === Rules.FULL_ACCESS || rule === accessRule) || false
    }

    return isEnabled
}

type UserRating = {
    rating: number
    win: number
    draw: number
    loss: number
    best?: number
    game_type: 'bullet' | 'blitz' | 'rapid' | 'classic'
    best_date?: Date
    total_games: number
    is_provisional: boolean
}

export type UserSearchResult = {
    avatarUrl: string
    country: string
    ratings: {
        blitz: UserRating
        bullet: UserRating
        rapid: UserRating
        classic: UserRating
    }
    title: string
    id: string
    userName: string
    status: 'online' | 'offline'
    reward: {
        tier: number
        season: string
    }
}

export const getPlayers = async (
    limit: number,
    search: string,
    filter: string,
): Promise<UserSearchResult[] | false> => {
    try {
        const response = await amsAPI.get(
            `/api/user/players${createQueryParams({
                limit,
                search,
                filter,
            })}`)
        return response.data.users
    } catch (error) {
        return false
    }
}

export interface IAccountDeleteConfig {
    timeHours: number
}

export const getAccountDeleteConfig = async (): Promise<IAccountDeleteConfig> => {
    try {
        const response = await amsAPI.get('/api/user/getAccountDeleteConfig')
        return response.data
    } catch (error) {
        return { timeHours: 0 }
    }
}

export interface IDeleteAccountDemandData {
    email: string
    userName: string
    redirectLink: string
}

export const deleteAccountDemand = async (requestData: IDeleteAccountDemandData) => {
    try {
        await amsAPI.post('/api/user/deleteAccountDemand', requestData)
        return true
    } catch (error) {
        return errorHandler(error)
    }
}

const prepareFileToUpload = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (event: any) => {
            const arrayBuffer = event.target.result
            const byteArray = new Uint8Array(arrayBuffer)
            resolve(Array.from(byteArray))
        }

        reader.onerror = (error) => {
            reject(error)
        }

        reader.readAsArrayBuffer(file)
    })
}

export const uploadAvatar = async (file: File) => {
    try {
        const byteArray = await prepareFileToUpload(file)
        await amsAPI.put('/api/user/profile/avatar', {
            avatar: byteArray,
        })
        return true
    } catch (error) {
        return errorHandler(error)
    }
}

export const getUserFollowList = async () => {
    try {
        const response = await amsAPI.get('/api/user/follow')
        return response.data
    } catch (error) {
        return []
    }
}

export const followUser = async (userId: string) => {
    try {
        await amsAPI.post('/api/user/follow', {
            id: userId,
        })
        return true
    } catch (error) {
        return errorHandler(error)
    }
}

export const unfollowUser = async (userId: string) => {
    try {
        await amsAPI.delete('/api/user/follow', {
            data: { id: userId },
        })
        return true
    } catch (error) {
        return errorHandler(error)
    }
}