import axios from 'axios';

const API_URL = import.meta.env.VITE_REACT_APP_MM_URL

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
})

// Add a request interceptor
api.interceptors.request.use(
    async (config) => {
        // TODO: need to fix CORS issue on BE side
        // let token = GetCookie(tokenCookieKey)
        // if (token) {
        //     config.headers['Authorization'] = 'Bearer ' + token
        // }

        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                // TODO: need to implement refresh token logic here
                // originalRequest.headers.Authorization = `Bearer ${token}`
                // return axios(originalRequest)
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }

        return Promise.reject(error)
    },
)

export default api