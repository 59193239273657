export enum MM_MESSAGES {
    MATCH_CREATED = 'matchCreated',
    PLAYER_IS_PLAYING = 'playerIsPlaying',
    PLAYER_NOT_PLAYING = 'playerNotPlaying',
    ALREADY_CONNECTED = 'alreadyConnected',
    ONLINE_USERS = 'onlineUsers',
    CHALLENGE_FOUND = 'challengeFound',
    CHALLENGE_CREATED = 'challengeCreated',
    CHALLENGE_DELETED = 'challengeDeleted',
    CHALLENGE_ACCEPTED = 'challengeAccepted',
    CHALLENGE_REJECTED = 'challengeRejected',
    CHALLENGE_COMPLETED = 'challengeCompleted',
    CHALLENGE_REMATCH_REQUESTED = 'challengeRematchRequested',
    REFRESH_PAGE = 'refreshPage',
    LOGOUT = 'logout',
}

export enum MM_REQUESTS {
    ONLINE_USERS = 'onlineUsers',
    MY_CHALLENGES = 'myChallenges',
    CHALLENGE_CREATE = 'challengeCreate',
    CHALLENGE_DELETE = 'challengeDelete',
    CHALLENGE_ACCEPT = 'challengeAccept',
    CHALLENGE_REJECT = 'challengeReject',
    MY_STATUS = 'myStatus',
    CHALLENGE_SCORE = 'challengeScore',
    CHALLENGE_REMATCH = 'challengeRematch',
    BOTS_LIST = 'botsList'
}

export enum MM_ERRORS {
    PLAYER_IS_SUSPENDED = 'PLAYER_IS_SUSPENDED',
    OPPONENT_IS_SUSPENDED = 'OPPONENT_IS_SUSPENDED',
}