import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ColorSelection, ColorSelector } from '../../../../components/colorSelector/ColorSelector'
import { CustomTimeSelector } from '../../../../components/CustomTimeSelector'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import BotAvatar from './BotAvatar'
import DetailsBlock from './DetailsBlock'
import FenInput from './FenInput'
import StrengthSelector from './StrengthSelector'

interface IProps {
    validFen: boolean
    setValidFen: (value: boolean) => void
}

const CustomPanel: React.FC<IProps> = (props) => {
    const { validFen, setValidFen } = props
    const settings = useStoreState((state) => state.gameView.settings)
    const selectedBot = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge.selectedBot)
    const searchOptions = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)

    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)
    const customBot = selectedBot?.difficultyLevel === -1

    return selectedBot ? (
        <Stack gap={2} sx={{ minHeight: 0, flexGrow: 1, overflowY: 'auto' }}>
            {!customBot && <Typography variant="h6">{selectedBot.name}</Typography>}
            {!customBot && (
                <Grid container columnSpacing={2}>
                    <Grid item xs={4}>
                        <BotAvatar bot={selectedBot} />
                    </Grid>
                    <Grid item xs={8}>
                        {selectedBot.bio && <Typography variant="body2">{selectedBot.bio}</Typography>}
                    </Grid>
                </Grid>
            )}
            {!customBot && (
                <DetailsBlock
                    title="Strength:"
                    value={
                        !settings.friendlyMode
                            ? `${selectedBot.strengthLevel} (${selectedBot.strengthRating})`
                            : `${selectedBot.strengthLevel}`
                    }
                    accentOnValue
                />
            )}
            {!customBot && selectedBot.startingFEN && selectedBot.defaultColor !== ColorSelection.RANDOM && (
                <DetailsBlock title="Color:" color={searchOptions.color} />
            )}
            <Stack
                gap={2}
                sx={{
                    flexGrow: 1,
                    justifyContent: !customBot ? 'space-between' : 'flex-start',
                }}
            >
                <CustomTimeSelector
                    time={searchOptions.minutes}
                    increment={searchOptions.increment}
                    setTime={(time) => {
                        setSearchOptions({
                            ...fullSearchOptions,
                            computerChallenge: {
                                ...searchOptions,
                                minutes: Number(time),
                            },
                        })
                    }}
                    setIncrement={(increment) => {
                        setSearchOptions({
                            ...fullSearchOptions,
                            computerChallenge: {
                                ...searchOptions,
                                increment: Number(increment),
                            },
                        })
                    }}
                />
                {customBot && <StrengthSelector />}
                {(!selectedBot.startingFEN || selectedBot.defaultColor === ColorSelection.RANDOM) && (
                    <ColorSelector
                        value={searchOptions.color}
                        onChange={(color) => {
                            setSearchOptions({
                                ...fullSearchOptions,
                                computerChallenge: {
                                    ...searchOptions,
                                    color: color,
                                },
                            })
                        }}
                    />
                )}
                {customBot && <FenInput validFen={validFen} setValidFen={setValidFen} />}
            </Stack>
        </Stack>
    ) : null
}

CustomPanel.displayName = 'CustomPanel'

export default CustomPanel
