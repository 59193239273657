import { format } from 'date-fns'
import puzzlesAPI from '../../../API/puzzlesConfig'
import { createQueryParams } from '../../../sharedComponents/src/globalHeader/common/queryParams'
import { errorHandler } from '../../../sharedComponents/src/globalHeader/functions/ams-functions'

export interface ITag {
    id: number
    name: string
    friendlyName: string
}

export enum PuzzleStatus {
    PASSED = 'passed',
    FAILED = 'failed',
    SKIPPED = 'skipped',
    SHOWED = 'solution_showed',
}

export interface IResolvePuzzle {
    puzzleId: string
    status: PuzzleStatus
    usedHints: number
    attempts: number
}

interface IPuzzleError {
    error?: any
}

interface IPuzzleData extends IPuzzleError {
    puzzle?: {
        id: string
        startingFen: string
        moves: string
        rating: number
        tags: Array<ITag>
        description: string
        isFirstMove: boolean
    }
    userPuzzleRating?: number
}

interface IDailyPuzzleData extends IPuzzleData {
    date?: string
    dateForNextPuzzle?: string
    nextPuzzleAvailable?: boolean
    previousPuzzleAvailable?: boolean
}

interface ITagsData extends IPuzzleError {
    tags?: Array<ITag>
}

interface IResolvePuzzleData extends IPuzzleError {
    result: boolean
    userPuzzleRating: number
}

export const getPuzzleTags = async (): Promise<ITagsData> => {
    try {
        const response = await puzzlesAPI.get('/api/get_tags')
        const defaultData = { tags: [] }
        return response.data ? { tags: response.data.puzzleTags } : defaultData
    } catch (error) {
        return errorHandler(error)
    }
}

export const getPracticePuzzle = async (tags: Array<number>): Promise<IPuzzleData> => {
    try {
        const response = await puzzlesAPI.put('/api/get_puzzle', { puzzleTags: tags })
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const resolvePracticePuzzle = async (puzzle: IResolvePuzzle): Promise<IResolvePuzzleData> => {
    try {
        const response = await puzzlesAPI.put('/api/resolve_puzzle', puzzle)
        return { result: true, userPuzzleRating: response.data.userPuzzleRating }
    } catch (error) {
        return { result: false, userPuzzleRating: 0, error: errorHandler(error) }
    }
}

export const getDailyPuzzle = async (date: Date): Promise<IDailyPuzzleData> => {
    try {
        const response = await puzzlesAPI.get(
            `/api/daily/puzzles/next${createQueryParams({
                date: format(date, 'yyyy-MM-dd')
            })}`
        )
        return response.data
    } catch (error) {
        return errorHandler(error)
    }
}

export const resolveDailyPuzzle = async (puzzle: IResolvePuzzle): Promise<IResolvePuzzleData> => {
    try {
        const { puzzleId, ...restOfPuzzle } = puzzle
        const response = await puzzlesAPI.put(`/api/daily/puzzles/${puzzleId}/resolve`, restOfPuzzle)
        return { result: true, userPuzzleRating: response.data.userPuzzleRating }
    } catch (error) {
        return { result: false, userPuzzleRating: 0, error: errorHandler(error) }
    }
}
