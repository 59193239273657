export enum ServerErrors {
    NO_PASSWORD_SET = 'NO_PASSWORD_SET',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    USER_EMAIL_NOT_CONFIRMED = 'USER_EMAIL_NOT_CONFIRMED',
    USERNAME_IS_MANDATORY_FOR_FIRST_LOGIN = 'USERNAME_IS_MANDATORY_FOR_FIRST_LOGIN',
    SOCIAL_LOGIN_MANDATORY_FIELDS_IS_MISSING = 'SOCIAL_LOGIN_MANDATORY_FIELDS_IS_MISSING',
    USERNAME_IS_NOT_ALLOWED = 'USERNAME_IS_NOT_ALLOWED',
    SEND_EMAIL_LIMIT_REACHED = 'SEND_EMAIL_LIMIT_REACHED',
    PRACTICE_PUZZLE_LIMIT_REACHED = 'PRACTICE_PUZZLE_LIMIT_REACHED',
    PRACTICE_PUZZLE_FEATURE_DISABLED = 'PRACTICE_PUZZLE_FEATURE_DISABLED',
}