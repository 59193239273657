import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import React from 'react'
import { TimeMode } from '../../store/types'
import { Blitz } from '../challengesList/gameTypeIcons/Blitz'
import { Bullet } from '../challengesList/gameTypeIcons/Bullet'
import { Rapid } from '../challengesList/gameTypeIcons/Rapid'

export type NewChallengeSelectorProps = {
    headerTitle: string
    launchedChallenges: (string | null)[] | string | undefined
    onSelected: (selected: any) => void
    vsHuman: boolean
    buttons: string[]
    isPlayAny?: boolean
    handlePlayAny?: () => void
    handleCustomButton?: (e: React.MouseEvent<HTMLElement>) => void
}

const modeIcons = {
    bullet: <Bullet />,
    blitz: <Blitz />,
    rapid: <Rapid />,
}
export function NewChallengeSelector(props: NewChallengeSelectorProps) {
    const {
        headerTitle,
        onSelected,
        vsHuman,
        launchedChallenges,
        buttons,
        handleCustomButton,
        handlePlayAny,
        isPlayAny,
    } = props

    const handleChange = (event: React.MouseEvent<HTMLElement>, newSelected: TimeMode[]) => {
        onSelected(newSelected)
    }

    return (
        <Stack direction="column" display={'flex'}>
            <Typography variant="h6">{headerTitle}</Typography>
            {/* //     {handlePlayAny && (
        //         <FormControlLabel
        //             sx={checkBox}
        //             value="end"
        //             control={<Checkbox size="medium" checked={isPlayAny} onChange={handlePlayAny} />}
        //             labelPlacement="end"
        //             label="Select All"
        //         />
        //     )}
        // </Stack> */}

            <ToggleButtonGroup
                color="primary"
                sx={(theme) => ({
                    gap: '8px',
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(3, 1fr)',
                    },
                })}
                value={launchedChallenges}
                onChange={handleChange}
                exclusive={!vsHuman}
            >
                {buttons.map((timeMode) => (
                    <ToggleButton
                        value={timeMode}
                        key={timeMode}
                        sx={(theme) => ({
                            padding: '8px',
                            margin: '0px !important',
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexWrap: 'nowrap',
                            '&.Mui-selected': {
                                '> .MuiLinearProgress-root': {
                                    display: 'block',
                                },
                            },
                        })}
                    >
                        {modeIcons[timeMode.split(',')[2]]}
                        <Box
                            zIndex={3}
                            width="100%"
                            display="flex"
                            alignItems="flex-start"
                            flexWrap="wrap"
                            flexDirection="column"
                        >
                            <Typography
                                fontSize="18px"
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                                fontFamily="Space Grotesk"
                                fontWeight={700}
                                variant="body1"
                                noWrap
                            >
                                {timeMode.split(',')[2]}
                            </Typography>
                            <Typography
                                noWrap
                                sx={{
                                    fontSize: '22px',
                                    fontWeight: 700,
                                }}
                                variant="body1"
                            >
                                {timeMode.split(',')[0]}+{timeMode.split(',')[1]}
                            </Typography>
                        </Box>
                        {vsHuman && (
                            <LinearProgress
                                sx={{
                                    opacity: '0.3',
                                    display: 'none',
                                    position: 'absolute',
                                    height: 'auto',
                                    top: -2,
                                    left: -2,
                                    bottom: -2,
                                    right: -2,
                                }}
                            />
                        )}
                    </ToggleButton>
                ))}
                {handleCustomButton && (
                    <ToggleButton sx={{ gridColumnStart: 'span 3' }} value="" onClick={handleCustomButton}>
                        <Typography fontSize="20px" fontFamily="Space Grotesk" fontWeight={700} variant="body1">
                            Custom
                        </Typography>
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </Stack>
    )
}
