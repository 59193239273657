import puzzlesAPI from '../../API/puzzlesConfig'
import { QuestPuzzle } from './types'

export const getPuzzleQuest = async (): Promise<QuestPuzzle> => {
    return await puzzlesAPI.get('/api/quest/puzzles/next')
        .then((response) => {
            return response.data
            // if (response.ok) {
            //     return response.json()
            // } else {
            //     try {
            //         if (response.status === 500) {
            //             throw new Error('Internal Server Error')
            //         }
            //         response.json().then((data) => {
            //             console.error(data.message)
            //         })
            //     } catch (error) {
            //         throw new Error('Failed to parse JSON')
            //     }
            // }
        })
        .then((data) => data)
        .catch((error) => {
            console.error('[] Error:', error)
        })
}

export const resolvePuzzle = async (id: string, passed: boolean): Promise<QuestPuzzle> => {
    return await puzzlesAPI.put(`/api/quest/puzzles/${id}/resolve`, { passed })
        .then((response) => {
            return response.data
            // if (response.ok) {
            //     return response.json()
            // }
            // else {
            //     try {
            //         response.json().then((data) => {
            //             throw new Error(data)
            //         })
            //     } catch (error) {
            //         throw new Error('Failed to parse JSON')
            //     }
            // }
        })
        .then((data) => data)
        .catch((error) => {
            console.error('[] Error:', error)
        })
}
