import { Box, Button, Stack } from '@mui/material'
import { nameOfSquare } from '../../../chess/basics'
import { getMoveById, getPositionById } from '../../../chess/gameTree'
import { Square } from '../../../react-chessboard/src/chessboard/types'
import { useResponsiveSizings } from '../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import CircularButton from '../../analysisView/components/analyisControls/CircularButton'
import FlipIcon from '../../gameView/components/gameEndControls/icons/Flip'
import ResignIcon from '../../gameView/components/gameEndControls/icons/Resign'
import HintIcon from '../../PuzzlesView/icons/Hint'

export const PuzzleQuestControls = () => {
    const layout = useResponsiveSizings()
    const gameTree = useStoreState((state) => state.puzzleQuestView.gameTree)
    const currentPosition = useStoreState((state) => state.puzzleQuestView.currentPosition)
    const myColor = useStoreState((state) => state.puzzleQuestView.myColor)
    const currentPuzzleNumber = useStoreState((state) => state.puzzleQuestView.currentPuzzleNumber)
    const showSolution = useStoreState((state) => state.puzzleQuestView.showSolution)
    const puzzleState = useStoreState((state) => state.puzzleQuestView.puzzleState)

    const flipBoard = useStoreActions((actions) => actions.puzzleQuestView.flipBoard)
    const setHint = useStoreActions((actions) => actions.puzzleQuestView.setHint)
    const setShowSolution = useStoreActions((actions) => actions.puzzleQuestView.setShowSolution)
    const setShowingSolution = useStoreActions((actions) => actions.puzzleQuestView.setShowingSolution)
    const setMoveStatus = useStoreActions((actions) => actions.puzzleQuestView.setMoveStatus)
    const nextPuzzle = useStoreState((state) => state.puzzleQuestView.nextPuzzle)
    const savePuzzle = useStoreActions((actions) => actions.puzzleQuestView.savePuzzle)
    const showRightSolution = useStoreActions((actions) => actions.puzzleQuestView.showRightSolution)

    const handleHint = () => {
        const currentProgressPosition = getPositionById(gameTree, currentPosition.id)
        const expectedProgressMove = getMoveById(gameTree, currentProgressPosition?.nextMoveIds[0])
        if (expectedProgressMove && currentProgressPosition.position.turn === myColor) {
            const nextMove = nameOfSquare(expectedProgressMove?.move.from)
            setHint(nextMove as Square)
        }
    }

    const handleShowSolution = () => {
        setShowingSolution(true)
        setShowSolution(true)
        setMoveStatus(undefined)
    }

    const handleShowRightSolution = () => {
        showRightSolution()
    }

    const showControls = layout.resolution.isDesktop || (layout.resolution.isMobile && !nextPuzzle)
    const showNextButton = layout.resolution.isDesktop || (layout.resolution.isMobile && nextPuzzle)
    const showSolutionButton = puzzleState === false && !showSolution

    return (
        <>
            {showControls && (
                <Box
                    sx={{
                        height: '44px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: 0,
                        gap: '16px',
                    }}
                >
                    <CircularButton
                        onClick={handleShowSolution}
                        title="Show Solution"
                        icon={<ResignIcon />}
                        disabled={Boolean(nextPuzzle) || showSolution || currentPosition.position.turn !== myColor}
                    />

                    <CircularButton onClick={flipBoard} title="Flip board" icon={<FlipIcon />} disabled={false} />

                    <CircularButton
                        onClick={handleHint}
                        title="Hint"
                        icon={<HintIcon />}
                        disabled={Boolean(nextPuzzle) || currentPosition.position.turn !== myColor}
                    />
                </Box>
            )}
            <Stack direction="row" gap={1}>
                {showSolutionButton && (
                    <Button onClick={handleShowRightSolution} fullWidth>
                        Solution
                    </Button>
                )}
                {showNextButton && (
                    <Button
                        disabled={!nextPuzzle || currentPuzzleNumber >= 10}
                        onClick={() => savePuzzle(nextPuzzle!)}
                        fullWidth
                    >
                        Next Puzzle
                    </Button>
                )}
            </Stack>
        </>
    )
}
