import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, IconButton, Typography, Zoom } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { getPuzzleQuestAsset } from '../../../sharedComponents/src/helpers/getPuzzleQuestAsset'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { getReward } from '../common'

const Card: React.FC<{
    title: string
    subtitle: string | number
    children?: React.ReactElement
    showSubtitle: boolean
}> = ({ title, subtitle, children, showSubtitle = true }) => {
    return (
        <Box
            sx={{
                padding: { xs: '12px', md: '24px' },
                width: { xs: '50%', md: '260px' },
                height: 'auto',
                aspectRatio: '1/1',
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 1, md: 2 },
                position: 'relative',
            }}
        >
            <Typography sx={{ fontSize: { xs: '16px', md: '24px' } }} textAlign="center" variant="h5">
                {title}
            </Typography>
            <Typography
                sx={{
                    fontSize: { xs: '16px', md: '20px' },
                    opacity: showSubtitle ? 1 : 0,
                    transition: 'opacity 3s',
                }}
                textAlign="center"
                variant="body1"
            >
                {subtitle}
            </Typography>
            <Box display="flex" justifyContent="center" flexGrow={1}>
                {children}
            </Box>
        </Box>
    )
}

export const WeekResultPopup = () => {
    const setResultsPopup = useStoreActions((state) => state.puzzleQuestView.setResultsPopup)
    const weekProgress = useStoreState((state) => state.puzzleQuestView.weekProgress)
    const [animatedRewardsTotal, setAnimatedRewardsTotal] = useState(0)
    const [initialised, setInitialised] = useState(false)
    const [playConfetti, setPlayConfetti] = useState(false)
    const [rewardEarned, setRewardEarned] = useState(false)

    const countdownFinished = weekProgress?.rewardsTotal === animatedRewardsTotal
    const reward = useMemo(() => getReward(weekProgress), [weekProgress])

    useEffect(() => {
        let interval
        if (weekProgress?.rewardsTotal && initialised) {
            interval = setInterval(() => {
                setAnimatedRewardsTotal((prev) => {
                    if (prev === weekProgress?.rewardsTotal) {
                        clearInterval(interval)
                        return prev
                    }
                    return prev + 1
                })
            }, 1000 / weekProgress?.rewardsTotal)
        }
        return () => clearInterval(interval)
    }, [weekProgress?.rewardsTotal, initialised])

    useEffect(() => {
        if (countdownFinished) {
            setTimeout(() => setRewardEarned(true), 1000)
            setTimeout(() => setPlayConfetti(true), 1500)
        }
    }, [countdownFinished])

    const handleClose = () => {
        setResultsPopup(false)
    }

    return (
        <Dialog
            onTransitionEnd={() => setInitialised(true)}
            TransitionComponent={Zoom}
            transitionDuration={1500}
            open
            PaperProps={{ sx: { justifyContent: 'center', backgroundColor: 'background.default' } }}
            onClose={handleClose}
        >
            <IconButton
                sx={{ position: 'absolute', top: { xs: 0, md: 8 }, right: { xs: 0, md: 8 } }}
                onClick={handleClose}
            >
                <CloseIcon fontSize="large" />
            </IconButton>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    py: { xs: 6, md: 8 },
                    px: { xs: 2, md: 8 },
                    gap: 6,
                }}
            >
                <Box gap={1} display="flex" flexDirection="column">
                    <Typography variant="h1" color="secondary">
                        Event Completed!
                    </Typography>
                    <Typography variant="body2">Here's what you accomplished!</Typography>
                </Box>

                <Box
                    display="flex"
                    sx={{
                        '> :first-child': {
                            backgroundColor: '#131D26',
                        },
                        '> :last-child': {
                            backgroundColor: '#242636',
                        },
                    }}
                >
                    <Card title="Total crowns" subtitle={animatedRewardsTotal} showSubtitle>
                        {initialised ? (
                            <DotLottieReact
                                segment={weekProgress?.rewardsTotal ? undefined : [0, 28]}
                                src="/assets/animations/crowns-animation.lottie"
                                autoplay
                            />
                        ) : undefined}
                    </Card>
                    <Card
                        title={Boolean(reward) ? 'Top Reward' : 'No Reward'}
                        subtitle="Earned"
                        showSubtitle={rewardEarned}
                    >
                        <>
                            {playConfetti && Boolean(reward) && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: '15%',
                                        right: 0,
                                    }}
                                >
                                    <DotLottieReact src="/assets/animations/confetti.lottie" autoplay speed={0.75} />
                                </Box>
                            )}
                            <Box
                                sx={{
                                    position: 'relative',
                                    p: '24px',
                                    width: '100%',
                                    '& img': {
                                        filter: 'drop-shadow(3px 3px 0 rgba(0, 0, 0, 0.25))',
                                    },
                                    '& img.disabled': {
                                        transform: rewardEarned && reward ? 'scale(0)' : 'scale(1)',
                                        transition: 'transform 0.5s',
                                        transformOrigin: 'bottom',
                                    },
                                    '& img.active': {
                                        '@keyframes growIn': {
                                            '0%': { transform: 'scale(0)' },
                                            '75%': { transform: 'scale(1.2)' },
                                            '100%': { transform: 'scale(1)' },
                                        },
                                        transform: 'scale(0)',
                                        animation: rewardEarned ? 'growIn 0.5s forwards' : 'none',
                                        transition: 'transform 0.5s',
                                        animationDelay: '0.5s',
                                        transformOrigin: 'bottom',
                                    },
                                    img: {
                                        position: 'absolute',
                                        bottom: 0,
                                        top: '10%',
                                        left: 0,
                                        width: '100%',
                                        height: '80%',
                                    },
                                }}
                            >
                                <img
                                    className="disabled"
                                    src={`${getPuzzleQuestAsset(weekProgress?.season!, reward || 1, 'disabled')}`}
                                />
                                {Boolean(reward) && (
                                    <img
                                        className="active"
                                        src={`${getPuzzleQuestAsset(weekProgress?.season!, reward, 'active')}`}
                                    />
                                )}
                            </Box>
                        </>
                    </Card>
                </Box>
            </Box>
        </Dialog>
    )
}
