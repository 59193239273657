import CloseIcon from '@mui/icons-material/Close'
import {
    Box,
    Button,
    Dialog,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Link,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material'
import throttle from 'lodash-es/throttle'
import { useEffect, useState } from 'react'
import { countries } from '../common/countries'
import { validateUsername } from '../functions/ams-functions'
import { ValidationState } from './types'

const throttledUsernameCheck = throttle(validateUsername, 500)

const inputStyle = {
    margin: '0 0 16px 0',
    borderColor: 'rgb(16 24 32)',
    '.MuiFormLabel-root': {
        color: '#101820',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInputBase-input': {
        borderBottom: '1px solid rgb(16 24 32)',
        color: 'rgb(16 24 32)',
    },
    '.MuiTypography-body1': {
        color: '#101820',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiTypography-body2': {
        color: 'red',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiInput-underline:after': {
        borderBottom: '1px solid rgb(16 24 32)',
    },
    '.MuiInputLabel-root.Mui-focused': {
        color: 'rgb(16, 24, 32)',
    },
}

const buttonStyle = {
    padding: '10px 57px',
    fontFamily: 'Fira Sans',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    fontSize: '21px',
    border: '2px solid #000000',
    width: '182px',
    height: '48px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0',
    '&.MuiButton-text': {
        paddingTop: '15px',
    },
    '&:hover': {
        backgroundColor: '#101820',
        color: '#FFFFFF',
    },

    '&:disabled': {
        color: '#C3C3C3',
        border: '2px solid #C3C3C3',
    },
}

const checkBox = {
    '.MuiTypography-body1': {
        color: 'rgb(16 24 32)',
        fontSize: '12px',
        fontFamily: 'Space Grotesk',
    },
    '.MuiSvgIcon-root': {
        fill: 'rgb(16 24 32)',
    },
    '.MuiButtonBase-root': {
        padding: '4px 4px 4px 10px ',
    },
    marginRight: '0',
}

const stackStyle = {
    padding: '16px',
    backgroundColor: '#fff',
    minWidth: '338px',
    maxWidth: '338px',
    '& .createAccountStack': {
        padding: '16px',
    },
    border: '2px solid #101820',
}

export interface SignUpSocialFormProps {
    onClose: () => void
    apiURL: string
    websiteURL: string
    onSubmit: (user_name: string, country: string, over12: boolean) => void
}

function SignUpSocialForm(props: SignUpSocialFormProps) {
    const { onClose, apiURL, websiteURL, onSubmit } = props
    const [isSecondPageValid, setIsSecondPageValid] = useState(false)
    const [isUsernameValid, setIsUsernameValid] = useState<boolean | undefined>(undefined)
    const [username, setUsername] = useState('')
    const [country, setCountry] = useState('')
    const [usernameCheckState, setUsernameCheckState] = useState(ValidationState.WAITING)
    const [over12, setOver12] = useState(true)

    //check if form data is valid
    useEffect(() => {
        if (isUsernameValid && usernameCheckState === ValidationState.FREE) {
            setIsSecondPageValid(true)
        } else {
            setIsSecondPageValid(false)
        }
    }, [isUsernameValid, country, usernameCheckState])

    // -------------------------------------------------------------- Username validation -----------------

    const [tooShort, setTooShort] = useState(false)
    const [tooLong, setTooLong] = useState(false)
    const [invalidChars, setInvalidChars] = useState(false)
    const [containsTitle, setContainsTitle] = useState(false)

    const nameChangeHandler = async (current: string) => {
        setUsername(current)
        if (current.length < 5) {
            setIsUsernameValid(false)
            setTooShort(true)
            return
        }
        if (current.length > 15) {
            setIsUsernameValid(false)
            setTooLong(true)
            return
        }

        if (!/^[a-zA-Z0-9](?:[a-zA-Z0-9]*[-._]?[a-zA-Z0-9])+$/.test(current)) {
            setIsUsernameValid(false)
            setInvalidChars(true)
            return
        }
        const titles = ['GM', 'IM', 'FM', 'WIM', 'WGM', 'CM', 'NM', 'WFM', 'WCM']
        const titleRegex = new RegExp(`^(${titles.join('|')})`)
        const usernameRegex = new RegExp(`(${titles.join('|')})$`)

        if (titleRegex.test(current) || usernameRegex.test(current)) {
            setIsUsernameValid(false)
            setContainsTitle(true)
            return
        }
        setContainsTitle(false)
        setTooShort(false)
        setTooLong(false)
        setInvalidChars(false)
        setUsernameCheckState(ValidationState.LOADING)
        setIsUsernameValid(true)
        await throttledUsernameCheck(current, setUsernameCheckState)
    }

    // -------------------------------------------------------------- Form submission -----------------

    const onSignUpSocialSubmit = () => {
        if (isSecondPageValid) {
            onSubmit(username, country, over12)
        }
    }

    return (
        <Dialog onClose={onClose} open={true} sx={{ zIndex: '1600' }} PaperProps={{ sx: { m: 0 } }}>
            <Stack style={stackStyle}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton sx={{ color: 'black' }} onClick={onClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Box>

                <Stack style={{ padding: 16 }}>
                    <Typography
                        variant="h2"
                        sx={{
                            color: 'rgb(16 24 32)',
                            textAlign: 'center',
                            pt: '30px',
                            pb: '40px',
                        }}
                    >
                        ALMOST DONE!
                    </Typography>

                    <FormControl sx={inputStyle} variant="standard">
                        <InputLabel htmlFor="username">Pick a username</InputLabel>
                        <Input
                            id="username"
                            sx={inputStyle}
                            type="username"
                            value={username}
                            style={{ marginBottom: 0 }}
                            onChange={(e) => nameChangeHandler(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton aria-label="" edge="end" tabIndex={-1}>
                                        <img src="/assets/images/icons/edit.svg" alt="username" />
                                    </IconButton>
                                </InputAdornment>
                            }
                            error={!isUsernameValid && isUsernameValid !== undefined}
                        />
                        {usernameCheckState && !tooLong && !tooShort && !containsTitle && !invalidChars && (
                            <FormHelperText id="confirm-password-helper-text">
                                <Typography variant="body2">
                                    {usernameCheckState === 'loading' && (
                                        <Typography style={{ color: 'black' }}>Checking...</Typography>
                                    )}
                                    {usernameCheckState === 'free' && (
                                        <Typography style={{ color: 'green' }}>Username available</Typography>
                                    )}
                                    {usernameCheckState === 'taken' && (
                                        <Typography style={{ color: 'red' }}>Username not available</Typography>
                                    )}
                                    {usernameCheckState === 'not_allowed' && (
                                        <Typography style={{ color: 'red' }}>Username can not be used</Typography>
                                    )}
                                </Typography>
                            </FormHelperText>
                        )}
                        {!isUsernameValid && isUsernameValid !== undefined && tooShort && (
                            <FormHelperText
                                id="confirm-password-helper-text"
                                style={{ color: 'red', marginTop: '4px' }}
                            >
                                <Typography variant="body2">Username too short</Typography>
                            </FormHelperText>
                        )}
                        {!isUsernameValid && isUsernameValid !== undefined && containsTitle && (
                            <FormHelperText
                                id="confirm-password-helper-text"
                                style={{ color: 'red', marginTop: '4px' }}
                            >
                                <Typography variant="body2">Titles not permitted</Typography>
                            </FormHelperText>
                        )}
                        {!isUsernameValid && isUsernameValid !== undefined && tooLong && (
                            <FormHelperText
                                id="confirm-password-helper-text"
                                style={{ color: 'red', marginTop: '4px' }}
                            >
                                <Typography variant="body2">Username must be a maximum of 15 characters</Typography>
                            </FormHelperText>
                        )}
                        {!isUsernameValid && isUsernameValid !== undefined && invalidChars && (
                            <FormHelperText
                                id="confirm-password-helper-text"
                                style={{ color: 'red', marginTop: '4px' }}
                            >
                                <Typography variant="body2">Username can only contain letters and numbers</Typography>
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl variant="standard" sx={inputStyle}>
                        <InputLabel style={{ color: 'black' }}>
                            <Typography
                                sx={{
                                    color: 'rgb(16 24 32)',
                                }}
                            >
                                Country
                            </Typography>
                        </InputLabel>
                        <Select
                            sx={{ inputStyle }}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            MenuProps={{
                                className: 'selectMenu',
                                sx: {
                                    '&.selectMenu': {
                                        zIndex: 1600,
                                    },
                                    '&  .MuiPopover-paper': {
                                        position: 'relative',
                                        width: 'fit-content',
                                        overflow: 'auto',
                                        maxHeight: '30vh',
                                        backgroundColor: 'white',
                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                                        borderRadius: '4px',
                                        color: 'rgb(16 24 32)',
                                        '& .MuiMenuItem-root:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                            transition: '0.2s ease-in-out',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value="US">United States</MenuItem>
                            <MenuItem value="CA">Canada</MenuItem>
                            <MenuItem value="GB">United Kingdom</MenuItem>
                            <ListSubheader sx={{ borderBottom: '1px solid gray' }} />
                            {countries.map((country) => (
                                <MenuItem key={country.code} value={country.code}>
                                    {country.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box
                        style={{
                            padding: '30px 0',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {/* <FormControlLabel
                            sx={checkBox}
                            value="end"
                            control={<Checkbox size="medium" />}
                            style={{
                                maxWidth: '240px',
                                color: '#101820',
                                fontSize: '15px',
                            }}
                            label="I am over 12."
                            labelPlacement="end"
                            onChange={() => setOver12(!over12)}
                        /> */}
                        <Typography sx={{ color: '#101820', py: 1 }} variant="body2">
                            By signing up, I accept the site
                            <Link
                                href={`${websiteURL}/eula`}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    pl: 0.5,
                                    textDecoration: 'underline',
                                }}
                                style={{ color: '#101820' }}
                            >
                                Terms and Conditions
                            </Link>
                        </Typography>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Button sx={buttonStyle} disabled={!isSecondPageValid} onClick={onSignUpSocialSubmit}>
                            Sign Up
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default SignUpSocialForm
