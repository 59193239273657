import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect, useRef, useState } from 'react'
import { loadScript } from '../globalHeader/common/loadScript'
import { loginSocial, upgradeGuestSocial } from '../globalHeader/functions/ams-functions'
import { analyticsManager } from '../globalHeader/services/analytics/AnalyticsManager'
import { ServerErrors } from '../utils/serverErrors'
import { SocialType, Theme } from './SocialLogin'

declare global {
    interface Window {
        google: any
    }
}

interface IProps {
    apiURL: string
    googleClientId: string
    isGuest: boolean
    onSignUpSocial: (socialResponse: any) => void
    onLoginSuccessful: () => void
    logoutHandler: () => Promise<void>
    theme: Theme
}

const GoogleAuth: React.FC<IProps> = (props) => {
    const { onSignUpSocial, onLoginSuccessful, isGuest, logoutHandler, apiURL, googleClientId, theme } = props
    const [socialType, setSocialType] = useState(SocialType.GOOGLE)
    const googleButton = useRef(null)

    useEffect(() => {
        const src = 'https://accounts.google.com/gsi/client'

        loadScript(src)
            .then(() => {
                window.google.accounts.id.initialize({
                    client_id: googleClientId,
                    callback: handleResponse,
                })
                window.google.accounts.id.renderButton(googleButton.current, {
                    theme: 'filled_black',
                    size: 'large',
                    shape: 'circle',
                    type: 'icon',
                })
            })
            .catch(console.error)

        return () => {
            const scriptTag = document.querySelector(`script[src="${src}"]`)
            if (scriptTag) document.body.removeChild(scriptTag)
        }
    }, [])

    const handleResponse = async (response: any) => {
        let data: any = null
        if (isGuest) {
            data = await upgradeGuestSocial(response.credential, response.clientId, socialType, logoutHandler)
        } else {
            data = await loginSocial(response.credential, response.clientId, socialType)
        }

        if (data.error) {
            if (
                data.error.code === ServerErrors.USERNAME_IS_MANDATORY_FOR_FIRST_LOGIN ||
                data.error.code === ServerErrors.SOCIAL_LOGIN_MANDATORY_FIELDS_IS_MISSING
            ) {
                onSignUpSocial({ ...response, socialType })
            } else {
                console.log('Google auth error:', data.error)
            }
        } else {
            analyticsManager.dispatchEvent('completeLogin', { authType: 'google' })
            onLoginSuccessful()
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                px: 2,
                colorScheme: 'light',
            }}
        >
            <div style={{ height: '44px', overflow: 'hidden' }} ref={googleButton}></div>
            <Typography
                sx={{
                    color: theme === 'light' ? 'rgb(16 24 32)' : '#F5F5F5',
                    fontWeight: 400,
                    pt: 1,
                    fontSize: '1rem',
                    fontFamily: 'Space Grotesk',
                }}
            >
                Google
            </Typography>
        </Box>
    )
}

GoogleAuth.displayName = 'GoogleAuth'

export default GoogleAuth
